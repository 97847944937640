@use "../../colors";
@use "../../typography";

.paper {
    padding: 24px 20px;
    border-radius: 16px;
    max-width: 540px;
}

.closeButton {
    position: absolute;
    top: 6px;
    right: 6px;
}

.title {
    @include typography.title2;
    color: colors.$grey900;
    margin: 16px 0;
}

.description {
    @include typography.body1;
    color: colors.$grey900;
    margin: 0 0 32px;
}

.animation {
    width: 100%;
    background: #d9d9d9;
    border-radius: 6px;
}

.upgradeButton {
    @include typography.action;
    color: colors.$white;
    min-height: 40px;
    margin-top: 32px;
    background: radial-gradient(50% 50% at 50% 50%, #5b57ff 0.12%, #4444d8 100%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    transition: none;
}
