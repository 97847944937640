@use "../../colors";
@use "../../typography";
@use "../../tint";

.container {
    padding: 5px;

    &.selected .link {
        background-color: colors.$purple50;
        @include typography.body2;
        color: colors.$purple500;
    }
}

.link {
    @include typography.body1;
    box-sizing: border-box;
    height: 40px;
    padding: 8px 20px 8px 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    &:hover {
        background-color: colors.$purple50;
    }
}

.icon {
    @include tint.grey800;
    width: 24px;
    height: 24px;
    margin-right: 8px;

    .container.selected & {
        @include tint.purple500;
    }
}
