@use "../../colors";
@use "../../typography";

.sectionContainer {
    border: 1px solid colors.$grey200;
    border-radius: 8px;
    padding: 8px 16px;
}

.sectionLabel {
    @include typography.caption2Regular;
    color: colors.$grey600;
}

.sectionValue {
    @include typography.title2;
    color: colors.$fullblack;
    margin-bottom: 8px;
}
