@use "../../typography";
@use "../../tint";

.container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 0;
    padding-bottom: 12px;
}

.title {
    @include typography.title3;
    margin: -8px 0 10px;
}

.viewerContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 12px 0 0;
    box-sizing: border-box;
}

.viewerCount {
    @include typography.action;
}

.insightStatsRow {
    margin: 0 0 10px;
}

.loader {
    margin: 20px auto;
}
