@use "../../typography";

.tooltip {
    @include typography.caption1Medium;
    background: #000;
    color: #fff;
    padding: 10px;
}

.infoIcon {
    width: 14px;
    height: 14px;
    cursor: help;
}
