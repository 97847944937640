@use "../../colors";
@use "../../typography";

.container {
    min-height: 100%;
    box-sizing: border-box;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
