@use "../../../typography";

.emptyContainer {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    align-items: center;
    justify-content: center;
    width: 450px;
    margin: auto;
    text-align: center;
}

.emptyIcon {
    width: 200px;
    height: 85px;
    margin-bottom: 16px;
}

.emptyTitle {
    @include typography.title2;
}

.emptyDescription {
    margin: 8px 0 16px;
    @include typography.body1;
}
