@use "../../colors";
@use "../../typography";

.container {
    display: flex;
    padding: 4px;
    padding-top: 42px;
    background-color: transparent;
    border-radius: 18px;

    &.proTier {
        background-color: colors.$purple500;
    }
}

.card {
    flex: 1;
    max-width: 384px;
    border-radius: 18px;
    border: 1px solid colors.$grey200;
    box-shadow:
        0px 6px 20px 0px rgba(0, 0, 0, 0.1),
        0px 2px 2px 0px rgba(0, 0, 0, 0.04);
    background: colors.$white;
    display: flex;
    flex-direction: column;
    padding: 28px;
}

.mostPopular {
    display: flex;
    align-items: center;
    @include typography.action;
    color: colors.$white;
    align-self: center;
    margin-top: -56px;
    margin-bottom: 36px;
    line-height: 20px;
}

.mostPopularIcon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 9px;
}

.title {
    @include typography.fontFamilyInter;
    font-size: 20px;
    font-weight: 600;
    line-height: 42px;
    margin: 0;
}

.description {
    @include typography.body1;
    color: colors.$grey600;
    margin-top: 10px;
    margin-bottom: 22px;
}

.priceContainer {
    display: flex;
}

.price {
    @include typography.fontFamilyInter;
    color: colors.$grey900;
    font-size: 42px;
    font-weight: 700;
    line-height: 42px;
    margin-left: 6px;
}

.dollarSign {
    @include typography.action;
}

.priceIcon {
    width: 42px;
    height: 42px;
}

.priceDescription {
    @include typography.body1;
    color: colors.$grey600;
    margin-top: 12px;
    margin-bottom: 26px;
}

.featureListHeading {
    @include typography.action;
    color: colors.$grey900;
    margin: 26px 0 18px;
}

.featureListItem {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.featureListItemIcon {
    min-width: 24px;
    margin-right: 12px;
    color: colors.$purple200;

    &.proTier {
        color: #fbbd24;
    }
}

.featureListItemIconSvg {
    width: 24px;
    height: 24px;
}

.featureListItemText {
    @include typography.body1;
}

.actionButton {
    @include typography.title1;
    font-size: 18px;
    font-weight: 500;
    padding: 14px;
}

.upgradeButton {
    color: colors.$white;
}

.contactButton {
    color: colors.$purple500;
}
