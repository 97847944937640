@use "../../colors";
@use "../../typography";
@use "../../tint";

.container {
    display: flex;
}

.linkInput {
    @include typography.body1;
    border: 1px solid #eaecef;
    outline: none;
    padding: 8px 10px;
    border-radius: 8px;
    flex: 1;
}

.copyLinkButton {
    margin-left: 10px;
}
