// Old design system
$grey1: #6c6f75;
$black1: #111111;
$fullblack: #000000;
$grey2: #999b9d;
$grey3: #c4c6c8;
$grey4: #e9e9ec;
$grey5: #eff1f4;
$grey6: #f2f2f2;
$blue: #5056f1;

// New design system
$white: #ffffff;
$grey50: #f8fafc;
$grey100: #f2f4f6;
$grey200: #eaecef;
$grey300: #dcdee0;
$grey400: #b8babc;
$grey500: #667085;
$grey600: #707274;
$grey700: #5d5e60;
$grey800: #3e4041;
$grey900: #1d1f20;

$purple10: #f2f3f9;
$purple50: #ebecfe;
$purple100: #cdcefc;
$purple200: #aaaefa;
$purple300: #868ef9;
$purple500: #5055f1;
$purple900: #2b19bb;

$green600: #43a047;
$green700: #3c9040;

$red100: #fee2e2;
$red200: #fecaca;
$red300: #fca5a5;
$red400: #f87171;
$red500: #ef4444;
$red600: #dc2626;
$red700: #b91c1c;
$red800: #991b1b;
$red900: #7f1d1d;

$blue500: #1c64f2;
