@use "../../colors";
@use "../../typography.scss";

.container {
    width: 100%;
    height: 275px;
    background-image: url("../../images/upgrade-prompt-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #020447;
    background-position: bottom;
    border-radius: 7px;
    padding: 32px 24px 24px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inner {
    width: 243px;
    color: white;
}

.header {
    @include typography.title3;
    color: inherit;
    margin: 12px 0 8px 0;
}

.text {
    margin: 0;
    @include typography.body1;
    color: rgba(255, 255, 255, 0.76);
}

.recommended {
    @include typography.caption2Regular;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 16px;

    .icon {
        font-size: 16px;
        margin-right: 4px;
    }
}
