@use "../../colors";
@use "../../typography";

$linear-progress-height: 6px;

.container {
    @include typography.caption2Regular;
    color: colors.$grey500;
}

.header {
    display: flex;
    justify-content: space-between;
}

.linearProgressRoot {
    margin: 4px 0 8px;
    height: $linear-progress-height;
    border-radius: $linear-progress-height;
    background-color: #e0e0e0;
}

.linearProgressBar {
    border-radius: $linear-progress-height;
    outline: 1px solid colors.$white;
    background-color: #06d6a0;
    &.full {
        background-color: #ff6a6a;
    }
}
