@use "../../colors";
@use "../../typography";
@use "../../tint";

$avatar-size: 36px;

.listItem {
    padding: 2px 6px;
    border-radius: 6px;
    &:hover {
        background-color: colors.$purple10;
    }
}

.listItemAvatar {
    min-width: calc(#{$avatar-size} + 12px);
}

.avatar {
    width: $avatar-size;
    height: $avatar-size;
}

.name {
    @include typography.action;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.numViews {
    @include typography.body1;
    color: colors.$grey600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.anonAvatar {
    background-color: colors.$purple50;
}
