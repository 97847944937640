@use "../../../colors";
@use "../../../typography";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.title {
    @include typography.caption1Medium;
    font-size: 32px;
    line-height: 39px;
    color: colors.$fullblack;
    margin-top: 100px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 325px;
    margin-top: 75px;
}

.label {
    @include typography.caption1Medium;
    font-weight: 600;
    color: colors.$grey1;
    margin-bottom: 4px;
}

.textField {
    height: 40px;
    margin-bottom: 20px;
}

.textFieldRoot {
    height: 40px;
    box-sizing: border-box;
    padding: 10px;
    background-color: colors.$white;
}

.errorHelperText {
    @include typography.caption2Regular;
    margin: 0 0 -16px;
}

.button {
    height: 40px;
    @include typography.caption1Medium;
    color: colors.$white;
}
