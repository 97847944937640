@use "../../colors";
@use "../../typography";

$dividerColor: #f2f4f6;

.sidebar {
    background: colors.$white;
    display: flex;
    flex-direction: column;
}

.orgUserInfoRow {
    padding: 15px;
    height: 30px;
    display: flex;
    align-items: center;
}

.orgInfo {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
}

.orgName {
    @include typography.body2;
    line-height: 30px;
    color: colors.$fullblack;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.orgLogo {
    max-height: 30px;
    max-width: 30px;
    border-radius: 6px;
    margin-right: 5px;
}

.avatar {
    height: 30px;
    width: 30px;
}

.divider {
    height: 1px;
    background-color: $dividerColor;
    margin-top: auto;
}

.subscriptionBanner {
    border-bottom: 1px solid $dividerColor;
}

.inviteButton {
    margin: 15px;
}
