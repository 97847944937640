@use "../../colors.scss";
@use "../../typography.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.logoUploader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 8px;
    background: #f8fafc;
    padding: 12px;
    & > :first-child {
        margin-right: 24px;
    }
}

.header {
    @include typography.title3;
    margin: 0;
}

.subheader {
    margin: 0;
    @include typography.body2;
    padding-top: 8px;
}

.txt {
    @include typography.body1;
    margin: 8px 0;
}

.separator {
    height: 1px;
    background-color: colors.$grey200;
    margin: 24px 0;
}

.upgradePill {
    margin-left: 4px;
}

.label {
    @include typography.caption1Medium;
    color: colors.$grey1;
    margin-bottom: 4px;
    display: block;
}

.textField {
    flex: 1;
    height: 41px;
    border-radius: 6px;
    background-color: colors.$white;
    margin-bottom: 20px;
}

.textFieldRoot {
    @include typography.textRegular;
    padding: 10px;
}

.buttonRow {
    display: flex;
    flex-direction: row;
}

.updateButton {
    height: 27px;
    padding: 5px 12px;
}

.colorInputGroup {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #707274;
    cursor: pointer;
    padding: 12px 0 8px;
}

.colorInput {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    padding: 0;
    box-sizing: content-box;
    margin-right: 12px;
    border: 1px solid #b8babc;
    background: none;
}

.colorInput::-webkit-color-swatch {
    border-radius: 6px;
    border: 0 none;
}

.colorInput::-webkit-color-swatch-wrapper {
    padding: 5px;
}
