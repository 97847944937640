@use "../../typography";
@use "../../colors";

.container {
    display: flex;
}

.icon {
    margin-top: 11px;
    margin-right: 2px;
    width: 16px;
    height: 16px;
}

.autocomplete {
    width: calc(100% - 18px);
}

.inputRoot:global(.MuiAutocomplete-inputRoot) {
    padding: 5px 0;
    border-color: transparent;

    & .input:global(.MuiAutocomplete-input) {
        padding: 6px;
        min-width: 60px;
    }
}

.input {
    @include typography.caption2Regular;
    color: colors.$grey800;

    &::placeholder {
        color: colors.$grey2;
        opacity: 1;
    }
}

.inputOutline {
    border-color: transparent;
}

.listbox {
    padding: 4px 8px 8px 8px;
    border-radius: 8px;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
}

.option:global(.MuiAutocomplete-option) {
    @include typography.caption2Regular;
    color: colors.$grey800;
    padding: 4px;
    margin-top: 4px;
}

.separator {
    border: none;
    border-top: 1px solid colors.$grey200;
    margin-top: 4px;
    margin-bottom: 0px;

    &:first-child {
        display: none;
        margin: 0;
    }
}

.createTagOption {
    color: colors.$blue;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
}

.createIcon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    // .material-icons is overwriting these two in
    // production but not locally
    &:global(.material-icons) {
        font-size: 12px;
        font-weight: 600;
    }
}

.readOnlyTags {
    margin-top: 3px;
}
