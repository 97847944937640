@use "../../typography";
@use "../../colors";

.stepContainer {
    min-height: calc(100% - 10px);
    width: 100%;
    max-width: 100vh;
    min-width: 60vw;
}

.detailsGrid {
    display: grid;
    grid-template-columns: 0fr minmax(0, 1fr) auto;
    // TODO: let container handle the row margins when grid layout is refactored.
    gap: 0 10px;
    grid-template-areas:
        "number title"
        ". description"
        ". actions";
    align-items: center;
    margin: 40px 0 10px 0;

    .title {
        grid-area: title;
    }
    .description {
        grid-area: description;
    }
    .stepNumberIcon {
        grid-area: number;
    }
    .actions {
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }
}

.stepNumberIcon {
    width: 30px;
    height: 30px;
}

.mediaContentWrapper {
    max-width: 100%;
    max-height: 75vh;
    display: flex;
    padding-bottom: 30px;
    justify-content: center;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.04))
        drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.1));
}

.video {
    margin: 0 auto;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.titleInput {
    @include typography.title3;
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.descriptionInput {
    font-size: 12px;
}
