@use "../../typography";
@use "../../colors";

.closeButton {
    position: absolute;
    top: 4px;
    right: 8px;
    color: white;
}

.subscriptionPill {
    margin-bottom: 20px;
    width: fit-content;
}

.title {
    @include typography.title2;
    color: colors.$white;
    margin-bottom: 8px;
    padding: 0;
}

.text {
    @include typography.textRegular;
    color: colors.$white;
    margin: 0 0 24px;
}

.button {
    @include typography.action;
    font-size: 14px;
    color: colors.$white;
    border-radius: 6px;
    background: radial-gradient(50% 50% at 50% 50%, #5b57ff 0.12%, #4444d8 100%);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
