@use "../../colors";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    height: fit-content;
}

.toggleContainer {
    margin: 32px auto 16px;
}
