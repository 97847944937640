@use "../../containers";

.container {
    display: flex;
    flex-direction: column;
    container: containers.$slideshow / size;
}

.inactiveView,
.stepView,
.placeholder {
    flex-grow: 1;
    min-height: 0;
}

.stepView {
    border-radius: inherit;
}
