@use "../../colors";

.container {
    display: flex;
    gap: 4px;
    padding: 4px;
    border-radius: 12px;
    border: 1px solid colors.$grey300;
    background-color: white;
}
