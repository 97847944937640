@use "../../typography";
@use "../../colors";

.pill {
    @include typography.body2;
    color: colors.$white;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 32px;
    background: radial-gradient(50% 50% at 50% 50%, #5b57ff 0.12%, #4444d8 100%);
}

.crownIcon {
    width: 16px;
    height: 16px;
}
