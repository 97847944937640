@use "../../../colors";
@use "../../../typography";
@use "../../../shadows";

.paper {
    @include shadows.shadow2;
    background-color: colors.$white;
    border-radius: 6px;
}

.menuItem {
    padding: 7px 27px 7px 5px;
    color: #4f4f4f;
    display: flex;
    align-items: center;
}

.icon {
    font-size: 18px;
    margin-right: 5px;
}
