@use "../../typography";

.content {
    padding: 28px 32px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}

.title {
    @include typography.title2;
    padding: 0 0 4px 0;
    margin: 0;
}

.subTitle {
    @include typography.body1;
    display: flex;
    align-items: center;
}

.infoIcon {
    margin-right: 8px;
}

.loading {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeButton {
    align-items: center;
    cursor: pointer;
}

.screenshot {
    max-width: 100%;
}

.cancelButton {
    margin-right: 8px;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.screenshotWrapper {
    margin-bottom: 24px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex-grow: 1;
    border-radius: 6px;
    border: 1px solid #f5f5f5;
}
