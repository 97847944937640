.container {
    margin: 24px;
}

.heading {
    margin: 24px 0 12px;
}

.stepper {
    background-color: transparent;
}

.setupRow {
    display: flex;
    margin: 12px 0;
    align-items: flex-end;
}

.setupTextField {
    min-width: 280px;
}

.setupButton:not(:first-child) {
    margin-left: 12px;
}

.playgroundParagraph {
    margin: 12px 0;
}

.playgroundButtons {
    margin: 12px 0;
    display: flex;
    :not(:first-child) {
        margin-left: 16px;
    }
}

.playgroundTextFields {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    :not(:first-child) {
        margin-top: 16px;
    }
}
