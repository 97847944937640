@use "../../colors.scss";
@use "../../typography.scss";
@use "../../tint.scss";

.sectionTitle {
    @include typography.title2;
    color: colors.$fullblack;
    margin: 30px 0 20px;
}

.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
}

.loader {
    align-self: center;
}

.txt {
    @include typography.body1;
    margin: 12px 0;
}

.newTagButton {
    height: 27px;
    padding: 5px 12px;
}

.tagList {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.tagListHeader {
    display: flex;
    margin-top: 12px;
    @include typography.body1;
    font-weight: 600;
}

.currentTags {
    width: 60%;
}

.tagItem {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 5px;
    margin-bottom: 6px;
    border: 1px solid colors.$grey200;
}

.numConnectedGuidesLink {
    @include typography.body1;
    color: colors.$blue;
    padding: 0 4px;
}

.numConnectedGuidesUnlink {
    @include typography.body1;
    color: colors.$grey2;
    padding: 0 4px;
}

.tagsIcon {
    @include tint.grey400;
    padding-bottom: 4px;
}

.tagTitle {
    display: flex;
    align-items: center;
    width: 60%;
}

.tagChip {
    margin-left: 12px;
}

.actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
}

.ellipsesIcon {
    @include tint.grey400;
    padding-bottom: 1px;
}

.tagListFooter {
    display: flex;
    align-items: center;
    margin: 16px 0;
    padding: 8px 12px;
    background-color: colors.$grey100;
}

.tagListFooterDescription {
    @include typography.caption2Regular;
    color: colors.$grey700;
}

.infoIcon {
    @include tint.purple500;
    padding-bottom: 6px;
    padding-right: 2px;
}

.emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
    margin: 12px 0 24px;
    border-radius: 4px;
    border: 1px solid colors.$grey200;
}

.emptyIconContainer {
    height: 40px;
    width: 40px;
    background-color: colors.$purple10;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyTagsIcon {
    @include tint.grey700;
    padding-bottom: 4px;
}

.emptyDescription {
    @include typography.caption1Regular;
    color: colors.$grey700;
    text-align: center;
    max-width: 180px;
    padding-top: 16px;
}

.menuItemIcon {
    color: colors.$grey800;
    font-size: 16px;
    margin-right: 8px;
}
