@use "../../../colors";
@use "../../../typography";
@use "../../../shadows";

$table-max-width: 660px;

.container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: colors.$purple10;
}

.titleRow {
    width: 100%;
    max-width: $table-max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 57px auto 60px;
}

.title {
    @include typography.title1;
    font-size: 32px;
    line-height: 50px;
    color: colors.$fullblack;
}

.inviteUserButton {
    border-radius: 4px;
    @include shadows.shadow4;
}

.tableContainer {
    max-width: $table-max-width;
    margin-bottom: 60px;
    border-radius: 12px;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.08),
        0px 2px 4px rgba(0, 0, 0, 0.12);
}

.tableHeader {
    @include typography.caption1Medium;
    color: colors.$grey700;
}

.tableCell {
    @include typography.caption1Medium;
    color: #333333;
}

.memberNameAvatarCell {
    display: flex;
    align-items: center;
}

.avatar {
    width: 33px;
    height: 33px;
    margin-right: 10px;
}

.menuIconButtonCell {
    padding: 0;
}
