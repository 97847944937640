@use "../../colors.scss";
@use "../../typography.scss";

.menu {
    background: colors.$white;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.04),
        0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-left: 14px;
    padding: 10px;
}

.list {
    padding: 0;
}

.menuItem {
    @include typography.action;
    padding: 10px;
}

.hiddenUploadInput {
    display: none;
}

.addButtonsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 16px;
}

.label {
    font-size: 12px;
    color: colors.$grey500;
}
