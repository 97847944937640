@use "../../colors";

$tooltipBackgroundColor: colors.$grey700;

.progressCircleButton {
    transform: rotate(-45deg);
}

.playPauseButton {
    transform: rotate(45deg);
    position: absolute;
    border-radius: 99px;
    background-color: white;
    color: colors.$grey800;
}

.tooltip {
    background-color: $tooltipBackgroundColor;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: colors.$white;
}

.tooltipArrow {
    color: $tooltipBackgroundColor;
}
