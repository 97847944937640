@use "../../colors";
@use "../../typography";

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.warningIcon {
    width: 30px;
    height: 30px;
}

.title {
    @include typography.title2;
    color: #d28b00;
    margin-bottom: 8px;
}

.sub {
    color: colors.$grey2;
    font-size: 10px;
    margin-bottom: 20px;
}
