@import "./colors";

@mixin fontFamilyInter {
    font-family: Inter, "Inter-fallback", sans-serif;
}

// Old design system

@mixin title1 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: $black1;
}

@mixin caption1Regular {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: $black1;
}

@mixin caption1Medium {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: $black1;
}

@mixin caption2Regular {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $black1;
}

@mixin caption2Semibold {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $black1;
}

@mixin textRegular {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: $black1;
}

@mixin textMedium {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $black1;
}

@mixin subheading {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $black1;
}

// New design system

@mixin title2 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: $grey800;
}

@mixin title3 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $grey800;
}

@mixin action {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $grey800;
}

@mixin body1 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
}

@mixin body2 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $grey800;
}

// Catalog's design

@mixin leading5 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0em;
}

@mixin leading6 {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0em;
}
