@use "../../../colors";
@use "../../../typography";

.container {
    min-height: 100%;
    box-sizing: border-box;
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: colors.$white;
}

.animation {
    width: 370px;
    height: 240px;
    overflow-y: hidden;
}

.progressBar {
    width: 162px;
    height: 3px;
    margin-top: 50px;
}

.loadingMessage {
    @include typography.caption2Regular;
    font-weight: 500;
    color: #333333;
    margin-top: 30px;
}
