.description {
    display: flex;
    align-items: center;
}

.logo {
    width: 20px;
    margin-left: 10px;
    &:last-child {
        margin-right: 10px;
    }
}
