@use "../../typography";
@use "../../colors";

$buttonPadding: 4px;

.messageBubble {
    position: relative;
    z-index: 10;
    &.invisible {
        visibility: hidden;
    }
}

.clickableBackground {
    position: absolute;
    inset: 0 0 0 0;
    background: transparent;
    border: none;
    border-radius: 0;
    &:hover {
        background: transparent;
    }
}

.editButton {
    margin-right: auto;
    @include typography.body1;
    color: colors.$grey400;
    text-decoration: underline;
    min-width: 0;
    padding: $buttonPadding;
    position: relative;
    left: calc(-#{$buttonPadding});
    z-index: 11;
    &:hover {
        background-color: colors.$grey800;
        text-decoration: underline;
    }
}
