@use "../../colors";
@use "../../typography";
@use "../../breakpoints";
@use "../../containers";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.messageBubble {
    position: relative;
    animation: fadeIn 500ms;
}

.clickableBackground {
    position: absolute;
    inset: 0 0 0 0;
    background: transparent;
    border: none;
    border-radius: 0;
    &:hover {
        background: transparent;
    }
}

.stepCount {
    font-weight: 400;
    color: colors.$grey400;
    margin-right: auto;

    font-size: 12px;
    line-height: 17px;

    @container #{containers.$slideshow} (min-width: #{breakpoints.$small}) {
        font-size: 13px;
        line-height: 18px;
    }

    @container #{containers.$slideshow} (min-width: #{breakpoints.$medium}) {
        font-size: 14px;
        line-height: 20px;
    }
}

.playPauseNarrationButton {
    display: flex;
}

.debugRect {
    outline: 4px dashed yellow;
    position: fixed;
    top: 0;
    left: 0;
}
