@use "../../typography";
@use "../../colors";

.label {
    display: inline-block;
    @include typography.action;
    margin-bottom: 15px;
}

.formContainer {
    display: flex;
    gap: 8px;
}

.emailTextField {
    flex: 1;
}

.emailInput {
    height: 38px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &:disabled {
        background: colors.$grey100;
    }
}

.inviteButton {
    height: 38px;
    min-width: 72px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.helperText {
    @include typography.body1;
    color: colors.$green600;
    margin: 6px 0 0;
    width: 120%;
}

.helperTextError {
    color: #fb5c5c;
}
