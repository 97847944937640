@use "../../colors";
@use "../../typography";

.container {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;

    &:hover .icon {
        visibility: visible;
    }

    &.editable:hover {
        outline: 1.5px solid colors.$purple200;
        border-radius: 5px;
    }
}

.field {
    @include typography.body1;
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 15px;
    box-sizing: border-box;
    max-width: calc(100% - 20px);

    &.placeholder {
        color: colors.$grey600;
    }
}

.inputEditing {
    width: 100%;
    max-width: 100%;
}

.inputInput {
    padding: 0;
    height: unset;
}

.multilineEndMargin {
    // matches the line-height, so that the text display is of the same height as
    // multiline text input, when the last line is empty.
    margin-bottom: 20px;
}

.valueDisplay {
    flex: 1;

    &:not(:first-child) {
        margin-left: 8px;
    }
    &:not(:last-child) {
        margin-right: 4px;
    }
}

.singlelineValue {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multilineValue {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
}

.singlelineValue {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.multilineValue {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
}

.icon {
    visibility: hidden;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.multilineIcon {
    align-self: flex-start;
    margin-top: 8px;
}
