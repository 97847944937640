@use "../../colors";
@use "../../typography";

.dropzone {
    @include typography.caption2Regular;
    color: colors.$fullblack;
    width: 132px;
    text-align: center;
}

.avatar {
    width: 132px;
    height: 132px;
    margin-bottom: 16px;
    font-size: 60px;
}
