@use "../../containers";
@use "../../colors";

.container {
    position: relative;
}

.screenshotImage {
    display: block;
    max-height: 100%;
    max-width: 100%;
    border-radius: 6px;
    border: 1px solid colors.$grey300;
}

.messageBubbleWrapper {
    position: absolute;
    inset: 0 0 0 0;
    // Use overflow clip so message bubble does not go outside bounds of screenshot image
    overflow: clip;
    container: containers.$slideshow / size;
}
