@use "../../colors";

$paperPadding: 32px;

.paper {
    padding: $paperPadding;
    box-sizing: border-box;
    width: 500px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.tabsFlexContainer {
    display: flex;
    gap: 15px;
}

.tabsRoot {
    border-bottom: 1px solid #e5e7eb;
    min-height: 0;
    margin-top: 6px;
}

.tabRoot {
    min-height: 0;
    min-width: 0;
    padding: 0 0 8px;
}

.upgradeBanner {
    margin: 6px 0 0;
}
