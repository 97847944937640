@import "../../colors";
@import "../../typography";

.container {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 32px;
    width: 32px;
    margin-bottom: 16px;
}

.title {
    @include textMedium;
}
