@use "../../colors";
@use "../../breakpoints";
@use "../../containers";

.panZoomWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

// Use higher specificity to override react-zoom-pan-pinch default style
.imageWrap.imageWrap {
    border-radius: 0;
    border: none;
    height: 100%;
    width: 100%;
}

.messageBubble {
    position: absolute;
    width: max-content;
    top: 0;
    left: 0;
    z-index: 20;
}

.clickableInterestPointHighlighter {
    z-index: 10;
}
