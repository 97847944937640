@use "../../colors";
@use "../../typography";

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.title {
    @include typography.title2;
    color: colors.$grey800;
    margin-bottom: 8px;
    text-align: center;
}

.metadata {
    @include typography.caption2Regular;
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    display: flex;
    justify-content: center;
    color: colors.$grey500;

    li {
        display: flex;
        align-items: center;
        margin: 0 8px;
    }
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: red;
}

.description {
    @include typography.caption2Regular;
    text-align: center;
    margin-bottom: 20px;
    max-width: 400px;
}
