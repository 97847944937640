@use "../../colors";
@use "../../typography";

.button {
    @include typography.action;
    display: flex;
    align-items: center;
}

.primary {
    // 1px more than secondary to account for the border
    padding: 9px 17px;
    color: colors.$white;
}

.secondary {
    padding: 8px 16px;
    color: colors.$purple500;
    border-color: colors.$purple300;
    &:disabled {
        border-color: colors.$purple200;
        color: colors.$purple200;
    }
}

.startIcon {
    width: 20px;
    height: 20px;
    margin-left: 0;
    margin-right: 8px;

    *:disabled & {
        opacity: 0.3;
    }
}
