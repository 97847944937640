@use "../../colors";
@use "../../typography";
@use "../../tint";

.paper {
    width: 100%;
    max-width: 322px;
    max-height: 50%;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.buttons {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
}

.closeButton {
    margin-left: auto;
}
