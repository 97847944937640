@use "../../typography";
@use "../../colors";

.container {
    padding: 20px;
    background-color: colors.$white;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.08),
        0px 2px 4px rgba(0, 0, 0, 0.12);
}

.title {
    &:not(:first-child) {
        margin-top: 15px;
    }
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.contentContainer .title {
    @include typography.title2;
}

.contextualContainer .title {
    @include typography.title3;
}

.description {
    @include typography.body1;
    &:not(:first-child) {
        margin-top: 15px;
    }
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}
