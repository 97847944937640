@use "../../colors";
@use "../../typography";

.button {
    border: 1px solid colors.$purple500;
    border-radius: 8px;
    padding: 8px 20px;
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-rows: auto max-content;
    justify-content: stretch;
    gap: 4px 8px;

    &:hover {
        background: colors.$purple10;
    }
}

.title {
    @include typography.body2;
    color: colors.$fullblack;
    text-align: start;
}

.description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: colors.$grey600;
    text-align: start;
}

.action {
    @include typography.body1;
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    gap: 6px;
    color: colors.$purple500;
    &.disabled {
        color: colors.$grey600;
    }
}

.icon {
    height: 20px;
    width: 20px;
}
