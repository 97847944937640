@use "../../typography";
@use "../../colors";

.title {
    text-align: center;
    @include typography.fontFamilyInter;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: colors.$fullblack;
    margin-bottom: 24px;
    padding: 0;
}

.iconContainer {
    background-color: #f4f4ff;
    width: 101px;
    height: 101px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}

.icon {
    font-size: 56px;
    line-height: 56px;
    color: colors.$blue;
}

.text {
    @include typography.textRegular;
    margin: 0;
    width: 308px;
}

.button {
    width: 300px;
    height: 40px;
    border-color: colors.$blue;
    margin-top: 26px;
}
