@use "../../typography";
@use "../../colors";

$logoSize: 30px;

.tooltip {
    @include typography.body1;
    background: colors.$grey900;
    border-radius: 6px;
    padding: 10px;
    color: colors.$white;
}

.drivewayButton {
    pointer-events: all;
    padding: 8px;
    min-width: 0;
    background-color: colors.$white;
    border: 1px solid colors.$grey300;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.04),
        0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: none;
    &:hover {
        background: linear-gradient(137.57deg, #f4ddff 9.72%, #e7e7ff 48.7%, #c2c6ff 95.57%);
    }
}

.drivewayLogo {
    width: $logoSize;
    height: $logoSize;
}
