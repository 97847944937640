@use "../../typography";
@use "../../colors";

.container {
    @include typography.fontFamilyInter;
    text-align: center;
    color: colors.$fullblack;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 860px;
}

.animation {
    max-width: 397px;
    max-height: 304px;
}

.heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    margin: 24px 0 17px;
}

.description {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 38px;
}

.retryButton {
    font-size: 15px;
    line-height: 20px;
    padding: 8px 20px;
}

.debugMessage {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 28px 0 0;
}
