@use "../../typography";

.container {
    display: flex;
}

.select,
.input {
    @include typography.body1;
    padding: 8px 15px;
}

.textField {
    margin-left: 10px;
    flex: 1;
}
