@use "../../colors";
@use "../../typography";

$sidebar-width: 240px;
$padding: 16px;

.container {
    width: $sidebar-width;
    background: white;
    border-right: 1px solid colors.$grey300;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;
}

.guideInfo {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 12px;
    color: colors.$grey900;
    border-bottom: 1px solid colors.$grey300;
}

.metadata {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.stepCount {
    display: flex;
    align-items: center;
}

.stepCountIcon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.createdAt {
    color: colors.$grey500;
    margin-left: auto;
}

.author {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.authorAvatar {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    font-size: 8px;
}

.duration {
    display: flex;
    align-items: center;
}

.durationIcon {
    width: 16px;
    margin-right: 2px;
}

.durationInput {
    font-size: 12px;
    line-height: 16px;
    padding: 7px 10px 7px 4px;
}

.tags {
    margin-bottom: 4px;
}

.descriptionInput {
    font-size: 12px;
    line-height: 20px;
    padding: 7px 6px;
    max-width: 100%;
}

.stepsContainer {
    display: flex;
    flex-direction: column;
}

.stepContainer {
    display: flex;
    padding: 32px 12px;
    position: relative;

    &:hover {
        box-shadow: inset 0 -1px #cdcefc;
        cursor: pointer;
        background: colors.$purple50;
    }

    &.active {
        background: colors.$purple50;
        .stepNumber {
            color: white;
            background: colors.$purple500;
        }
    }
}

.stepInnerContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.stepNumber {
    background: colors.$purple50;
    min-width: 22px;
    height: 22px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$purple500;
    font-weight: 600;
}

.stepInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.stepTitle {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}

.stepImage {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.actionStepImage {
    border: 1px solid colors.$grey300;
}

.navLinks {
    flex: 1;
}

.inviteButton {
    align-self: center;
    margin-bottom: 16px;
    width: calc(100% - 32px);
}
