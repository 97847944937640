@use "../../typography";
@use "../../colors";

.root {
    height: unset;
    justify-content: unset;
    background-color: colors.$grey200;
    margin: 4px 3px;
    padding: 4px 8px;
    border-radius: 10px;
    max-width: 100%;
}

.label {
    @include typography.caption2Regular;
    color: colors.$grey900;
    padding: 0;
}

.multiline {
    overflow: unset;
    white-space: normal;
    overflow-wrap: anywhere;
}
