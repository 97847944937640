@use "../../colors";
@use "../../typography";

.label {
    @include typography.caption1Medium;
    color: colors.$grey1;
    margin-bottom: 4px;
    display: block;
}

.textField {
    flex: 1;
    height: 41px;
    border-radius: 6px;
    background-color: colors.$white;
    margin-bottom: 20px;
}

.textFieldRoot {
    @include typography.textRegular;
    padding: 10px;
}

.container {
    display: flex;
    flex-direction: row;
}

.avatarContainer {
    width: 132px;
    height: 180px;
    margin-right: 32px;
    margin-top: 21px;
}

.profileFormColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.buttonRow {
    display: flex;
    flex-direction: row;
}

%button {
    height: 27px;
    padding: 5px 12px;
}

.updateButton {
    @extend %button;
}

.outlinedButton {
    @extend %button;
    margin-left: 10px;
    color: colors.$blue;
    border-color: colors.$blue;
}

.errorHelperText {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 -16px;
}
