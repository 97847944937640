@use "../../colors";
@use "../../typography";

$avatar-size: 32px;

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
}

.header {
    display: flex;
    align-items: center;
    margin: 8px 0;
    @include typography.action;
}

.avatar {
    margin-right: 12px;
    width: $avatar-size;
    height: $avatar-size;
}

.viewTimesHeading {
    @include typography.action;
}

.viewTime {
    @include typography.body1;
    color: colors.$grey600;
    margin-top: 8px;
}

.loader {
    align-self: center;
    margin: 20px 0;
}

.errorMessage {
    text-align: center;
}
