@use "../../colors";
@use "../../typography";
@use "../../tint";

.readOnlyTotalCount {
    @include typography.action;
    color: colors.$grey600;
}

.icon {
    @include tint.purple500;
}

.popover {
    padding: 10px;
}
