@use "../../colors";

.container {
    position: relative;
}

.background {
    width: 100%;
    height: 100%;
}

.filter {
    backdrop-filter: blur(12px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
}

.icon {
    position: absolute;
    bottom: 6px;
    right: 6px;
    color: colors.$white;
    background: black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 5px;
}
