$shadow4:
    0px 1px 3px rgba(0, 0, 0, 0.15),
    0px 4px 8px rgba(236, 236, 236, 0.9);

.button {
    border-radius: 4px;
}

.disableElevation {
    box-shadow: $shadow4;
    &:hover {
        box-shadow: $shadow4;
    }
    &:disabled {
        box-shadow: $shadow4;
    }
}
