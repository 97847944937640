@use "../../typography.scss";
@use "../../colors.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 42px;
}

.header {
    display: flex;
    gap: 10px;
}

.stepNumber {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: colors.$grey200;
    border-radius: 99px;
    @include typography.body1;
    height: 30px;
    width: 30px;
    margin-top: -2.5px;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    @include typography.title3;
    margin: 0;
}

.subtitle {
    @include typography.body1;
    margin: 0;
}

.screenshotContainer {
    height: 360px;
    width: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;

    &:hover .zoomControls {
        opacity: 1;
    }
}

.screenshot.screenshot {
    position: relative;
    border: 1px solid colors.$grey200;
    border-radius: 8px;
    height: 100%;
    width: 100%;
}

.zoomControls {
    display: flex;
    gap: 6px;
    justify-content: space-between;
    position: absolute;
    bottom: 8px;
    opacity: 0;
    right: 8px;
    transition: all 300ms ease;

    &:focus-within {
        opacity: 1;
    }

    > button {
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        padding: 0;
        border: none;
        color: #fff;
        cursor: pointer;
        border-radius: 50px;
        background: colors.$fullblack;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
        transition: 300ms opacity ease;

        &:hover {
            opacity: 1;
        }
    }
}

.video {
    border: 1px solid colors.$grey200;
    border-radius: 8px;
    width: 600px;
}

.video {
    border: 1px solid colors.$grey200;
    border-radius: 8px;
}

.audioContainer {
    display: flex;
    height: 40px;
    padding: 10px 0 0;
}

audio {
    width: 250px;
    height: inherit;
}

.audioContainer audio::-webkit-media-controls-play-button,
.audioContainer audio::-webkit-media-controls-panel {
    background-color: colors.$grey100;
    padding: 0 6px;
}
