@use "../../../colors";
@use "../../../typography";

.container {
    background-color: colors.$purple10;
    padding: 24px;
}

.body {
    width: 510px;
    margin-left: auto;
    margin-right: auto;
}

.pageTitle {
    @include typography.title1;
    font-size: 32px;
    line-height: 42px;
    color: colors.$fullblack;
    text-align: center;
    margin: 100px 0 0 0;
}

.sectionTitle {
    @include typography.title2;
    color: colors.$fullblack;
    margin: 30px 0 20px;
}

.organizationSettings,
.subscriptionSettings {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
