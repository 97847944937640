.container {
    display: grid;
    grid-template-columns: 1fr 600px 1fr;
    gap: 16px;
    background-color: white !important;
    height: 100%;
    width: 100%;
    padding: 16px 0;
}

.column {
    background-color: #ccc;
    padding: 16px;
    width: 100%;
}

.leftColumn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.middleColumn {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}
