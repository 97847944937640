@use "../../typography.scss";
@use "../../colors.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
}

.description {
    margin: 0 0 32px 0;
    @include typography.body1;
}

.createdAt {
    @include typography.body1;
    color: colors.$grey800;
}
.authorContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.author {
    display: flex;
    align-items: center;
}

.authorLabel {
    @include typography.body2;
}

.authorAvatar {
    width: 30px;
    height: 30px;
    margin-right: 6px;
}
