@use "../../typography";
@use "../../colors";

$driveway-logo-size: 20px;

.badge {
    @include typography.action;
    box-sizing: border-box;
    padding: 8px 16px 8px calc(#{$driveway-logo-size} + 24px);
    max-height: 36px;
    display: flex;
    align-items: center;
    border: 1px solid colors.$purple300;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    color: colors.$purple500;
    background: url("../../icons/driveway-logo.svg") no-repeat 16px center;
    background-size: $driveway-logo-size auto;
    background-color: white;
}
