@use "../../typography";
@use "../../colors";

.dialogTitle {
    @include typography.title2;
    padding: 24px 20px 10px;
}

.dialogContent {
    @include typography.body1;
    display: flex;
    flex-direction: column;
}

.description {
    margin: 0;
}

.loader {
    align-self: center;
    padding: 48px 0;
}

.errorMessage {
    padding: 48px 0;
}

.dialogActions {
    padding: 0 24px 16px;
}

.sectionHeading {
    @include typography.body2;
    color: colors.$fullblack;
    margin: 24px 0 10px;
}

.sectionHeadingInactive,
.formControlLabelDisabled {
    color: colors.$grey2;
}

.activateSection {
    display: flex;
    align-items: center;
}

.activeDetails {
    flex: 1;
}

.enabledSwitch {
    margin-top: 24px;
}

.timePickers {
    margin-top: 10px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.urlPatternInput {
    margin-top: 8px;
}

.audienceRadioGroup {
    margin-bottom: 8px;
}

.select,
.timePickerInput {
    padding: 8px 15px;
    @include typography.body1;
}

.menuItem {
    padding: 0;
}

.audienceIcon {
    height: 20px;
    width: 20px;
}

.dialogButton {
    font-size: 15px;
    font-weight: 500;
    padding: 5px 10px;
}
