@use "../../colors";
@use "../../typography";

.cardTitle {
    @include typography.body1;
    font-weight: 400;
    margin: 0 0 15px;
}

.planName {
    @include typography.title3;
    margin-bottom: 4px;
}

.planDescription {
    @include typography.caption2Regular;
    color: colors.$grey800;
    margin-top: 2px;
}

.button {
    margin-top: 18px;
    margin-left: auto;
    padding: 8px 20px;
}
