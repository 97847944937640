@use "../../colors";
@use "../../typography";

.container {
    display: flex;
}

.menu {
    background: white;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.04),
        0px 6px 20px rgba(0, 0, 0, 0.1);
}

.menuButton {
    width: 40px;
}

.menuItem {
    @include typography.leading5;

    &:hover {
        background-color: colors.$grey200;
    }
}

.menuIcon {
    margin-right: 8px;
}

audio {
    width: 250px;
    height: inherit;
}

.container audio::-webkit-media-controls-play-button,
.container audio::-webkit-media-controls-panel {
    background-color: white;
    padding: 0;
}

.disabledAudio {
    pointer-events: none;
    opacity: 0.5;
}
