@use "../../colors";
@use "../../typography";

.addButton {
    margin-right: 20px;
}

.title {
    @include typography.title2;
}

.buttonRow {
    margin-top: 24px;
}

.cancelButton {
    margin-left: 8px;
}

.paper {
    border-radius: 10px;
    width: 470px;
    padding: 16px 8px;
    box-sizing: border-box;
}
