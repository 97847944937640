@use "../../typography";

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include typography.body1;
}

.downloadExtensionMessage {
    width: 420px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
