.pillRoot {
    min-width: 0;
    min-height: 0;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
}

.selectedPill {
    background: #f7f7ff;
    font-weight: 500;
    color: #5055f1;
}
