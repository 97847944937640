@use "../../colors";
@use "../../typography";

.iconButton {
    color: colors.$grey800;
    position: relative;
}

.tooltip {
    @include typography.caption2Regular;
    color: colors.$white;
    box-sizing: border-box;
    padding: 6px 11px;
    max-width: 236px;
    background: #393939;
    border-radius: 4px;
}

.crownIcon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: -12px;
    right: 0;
}
