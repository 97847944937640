@use "../../colors";

.container {
    background-color: #f4f6ff;
    border: 1px solid colors.$purple200;
    border-radius: 4px;
    padding: 12px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: colors.$purple500;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: start;
}

.subscriptionPill {
    height: 24px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    margin-right: 12px;
}
