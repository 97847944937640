@use "../../typography";

$watermarkOffsetForSlideshowNav: 90px;

.container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.logo {
    margin-left: auto;
}

.mediaContent {
    flex: 1;
    overflow: hidden;
    padding: 0;
}

.slideshowNavigation {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 140px;
    border-radius: inherit;
    z-index: 30;
}

.drivewayWatermark {
    position: absolute;
    bottom: 20px;
    right: 20px;
    transition: 300ms bottom ease;
    z-index: 40;
    &.offsetForSlideshowNav {
        bottom: $watermarkOffsetForSlideshowNav;
    }
}
