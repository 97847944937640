@use "../../colors";
@use "../../typography";

.dropzone {
    @include typography.caption2Regular;
    color: colors.$fullblack;
    width: 132px;
    text-align: center;
    position: relative;
}

.dropzone.logo {
    width: 100px;
}

.dropzone.wordmark {
    width: 200px;
}

.logo,
.wordmark {
    position: relative; /* This will make sure the loading container is positioned relative to these elements */
}

.logo {
    width: 100px;
    height: 100px;
    font-size: 60px;
}

.logo img,
.wordmark img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.wordmark {
    width: 200px;
    height: 100px;
    font-size: 60px;
}

.loadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* This will ensure the loading container takes the full width of the parent */
    height: 100%; /* This will ensure the loading container takes the full height of the parent */
}

.blank {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: colors.$grey200;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blankIcon {
    font-size: 56px;
    color: colors.$grey500;
}
