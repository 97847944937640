@use "../../typography";

.content {
    padding: 28px 32px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.title {
    @include typography.title2;
    padding: 0 0 4px 0;
    margin: 0;
}
