@use "../../colors";
@use "../../typography";

.stopIcon {
    color: colors.$red500;
    animation: blink 1s infinite;
}

@keyframes blink {
    50% {
        opacity: 0.5;
    }
}

.activeRecording {
    display: flex;
}

.activeRecordingTime {
    @include typography.body1;
}

.stopButton {
    border-radius: 100px;
    margin: 4px;
    display: flex;
    gap: 4px;
    width: 80px;

    &:hover {
        background-color: colors.$grey100;
    }
}

.tooltip {
    @include typography.caption2Regular;
    color: colors.$white;
    box-sizing: border-box;
    padding: 6px 11px;
    max-width: 236px;
    background: #393939;
    border-radius: 4px;
}
