.installButton {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 400;
}

.chromeIcon {
    margin-right: 5px;
}
