@use "../../typography";
@use "../../colors";

.container {
    padding: 16px;
    background-color: white;
    width: 335px;
    height: 348px;
}

.topSection,
.bottomSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.divider {
    margin: 0px -16px 16px -155px;
}

.title,
.selectAll,
.clearAll,
.checkboxLabel {
    font-size: 12px;
    font-weight: 400;
}

.title {
    color: colors.$grey700;
    font-weight: 600;
}

.helperContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.helperIcon {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: colors.$purple10;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 20px;
        height: 20px;
    }
}

.helperMessage {
    @include typography.caption2Regular;
    color: colors.$grey700;
    width: 227px;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    height: 250px;
    overflow: scroll;
    padding-bottom: 16px;
}

.clearAll {
    margin-left: -139px;
}

.fade {
    position: absolute;
    top: 279px;
    height: 32px;
    width: 367px;
    margin-left: -16px;
    background: linear-gradient(0deg, white 30%, transparent);
}
