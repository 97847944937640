@use "../../typography";
@use "../../colors";

.container {
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: repeat(2, auto);
    justify-content: stretch;
    align-items: center;
    row-gap: 4px;
}

.label {
    @include typography.action;
    color: colors.$grey800;
}

.description {
    @include typography.body1;
    color: colors.$grey800;
}

.switch {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
}
