@use "../../../colors";
@use "../../../typography";

.pageContainer {
    height: 100%;
    width: 100%;
}

.contentContainer {
    display: flex;
    height: calc(100% - 62px);
}

.placeholder {
    flex: 1;
}

.pageTitle {
    @include typography.title3;
    color: colors.$fullblack;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
}

.guideActions {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 12px;
}

.orgLogo {
    margin-right: 16px;
}

.logo {
    width: 38px;
    height: 38px;
}

.wordmark {
    width: 112px;
    height: 56px;
}

.logo img,
.wordmark img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
