@use "../../colors";
@use "../../typography";

$arrowSize: 20px;

.paper {
    background-color: transparent;
    box-shadow: none;
    width: 288px;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    margin-top: 21px;
    padding: 16px;
    background-color: #03054a;
    border-radius: 8px;
    gap: 8px;

    &.arrowCenter::before {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: calc(50% - (#{$arrowSize} / 2));
        width: $arrowSize;
        height: $arrowSize;
        background-color: #03054a;
        transform: rotate(45deg);
    }

    &.arrowRight::before {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        right: calc(12% - (#{$arrowSize} / 2));
        width: $arrowSize;
        height: $arrowSize;
        background-color: #03054a;
        transform: rotate(45deg);
    }
}

.title {
    @include typography.title3;
    color: colors.$white;
    margin: 0;
}

.description {
    @include typography.body1;
    align-self: stretch;
    color: rgba(255, 255, 255, 0.76);
    margin: 0;
}

.button {
    margin-top: 8px;
    @include typography.body1;
    color: colors.$white;
    padding: 9px 16px;
}
