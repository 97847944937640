@use "../../../colors";
@use "../../../typography";
@use "../../../tint";

$sidebar-width: 240px;

.sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;
    z-index: 1;
    border-right: 1px solid colors.$grey300;
}

.pageContainer {
    min-height: 100%;
    background-color: colors.$purple10;
    height: 100%;
    display: flex;
    overflow: auto;
}

.container {
    flex: 1;
    padding-left: $sidebar-width;
}

.content {
    display: flex;
    justify-content: center;
    flex: 1;
}

.centeredPanel {
    width: 100%;
    // 4 columns
    max-width: 1240px;
    margin-left: 50px;
    margin-right: 50px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin: 56px 0 20px;
}

.title {
    @include typography.title1;
    margin: 0;
    font-size: 32px;
    line-height: 34px;
}

.createGuideButtonContainer {
    margin-top: 50px;
}

.gridContainer {
    --grid-layout-gap: 1rem;
    --grid-column-count: 4;
    --grid-item--min-width: 260px;

    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
}

.topMenu {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
}

.sortByContainer {
    height: 34px;

    & :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
    }
}

.sortByLabel {
    @include typography.body1;
    color: colors.$grey700;
    padding-right: 6px;
}

// Add MUI's classname to increase specificity
.select:global(.MuiSelect-select) {
    width: 140px;
    box-sizing: border-box;
    padding: 8px 33px 8px 17px;
    border: 1px solid colors.$grey300;
    @include typography.body1;
}

.selectMenuPaper {
    width: 140px;
    border-radius: 6px;
    background: colors.$white;
    box-shadow:
        0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.selectMenuList {
    padding: 0;
}

.menuItem {
    border-radius: 0;
    padding: 12px 16px;
    @include typography.leading5;

    &:hover {
        background-color: colors.$grey300;
    }

    &.menuItemSelected {
        background: inherit;
    }
}

.starCheckbox {
    margin-left: 8px;
    padding: 5px;
    border: 1px solid colors.$grey300;
    border-radius: 4px;
}

.starIconFilled,
.starIconBlank {
    height: 24px;
    width: 24px;
}

.starIconBlank {
    @include tint.grey800;
}

.clearFiltersButton {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 400;
    height: 40px;
}
