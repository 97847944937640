@use "../../colors";
@use "../../tint";

.avatar {
    background-color: colors.$purple50;
}

.icon {
    width: 20px;
    height: 20px;
    @include tint.purple500;
}
