@use "../../colors";

$backgroundColor: rgba(255, 255, 255, 0.15);
$defaultShadowColor: rgba(colors.$blue500, 0.9);

@keyframes pulseAnimation {
    0% {
        box-shadow: 0 0 0 0 var(--highlight-shadow-color, $defaultShadowColor);
    }
    60% {
        box-shadow: 0 0 0 16px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.container {
    position: absolute;
    border: none;
    border-radius: 4px;
    min-width: 0;
    background: $backgroundColor;
    animation-name: pulseAnimation;
    animation-duration: 1800ms;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    pointer-events: all;
    &:hover {
        background: transparent;
    }
}
