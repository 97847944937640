@use "../../typography";
@use "../../colors";

.container {
    @include typography.fontFamilyInter;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.animation {
    width: 324px;
    height: 300px;
}

.heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: colors.$fullblack;
    margin: 38px 0 17px;
}

.description {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 38px;
    max-width: 556px;
    text-align: center;
}

.goToWorkspaceButton {
    font-size: 15px;
    line-height: 20px;
    padding: 8px 20px;
}
