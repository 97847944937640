.button {
    display: flex;
    align-items: center;
}

.crownIcon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}
