@use "../../../tint.scss";

.star {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.blank {
    @include tint.white;
    opacity: 0.8;
}

.button {
    padding: 0;
}
