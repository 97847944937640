// See https://stackoverflow.com/a/53336754 on how to tint an SVG icon

@mixin purple500 {
    filter: invert(29%) sepia(59%) saturate(1691%) hue-rotate(219deg) brightness(107%)
        contrast(107%);
}

@mixin grey400 {
    filter: invert(81%) sepia(7%) saturate(82%) hue-rotate(169deg) brightness(93%) contrast(86%);
}

@mixin grey700 {
    filter: invert(35%) sepia(3%) saturate(309%) hue-rotate(182deg) brightness(98%) contrast(86%);
}

@mixin grey800 {
    filter: brightness(0) saturate(100%) invert(24%) sepia(12%) saturate(115%) hue-rotate(156deg)
        brightness(92%) contrast(91%);
}

@mixin white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(120%) hue-rotate(43deg)
        brightness(116%) contrast(100%);
}

// Old design system color tints
@mixin lightGray {
    filter: invert(64%) sepia(7%) saturate(97%) hue-rotate(169deg) brightness(96%) contrast(88%);
}

@mixin black1 {
    filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(1772%) hue-rotate(336deg)
        brightness(94%) contrast(87%);
}
