@use "../../typography";
@use "../../colors";

.paper {
    border-radius: 8px;
    width: 500px;
    padding: 20px;
    box-sizing: border-box;

    &.success {
        align-items: center;
        width: 365px;
        padding: 30px 28px;
    }

    &.upgradeView {
        width: 483px;
        padding: 40px 20px 30px;
        background: #03054a;
    }
}
