@use "../../typography";
@use "../../colors";

// Based on Bootstrap
// See https://github.com/twbs/bootstrap/blob/ce114117a8710016022c40e717cb1f3ec95a5d7f/scss/mixins/_visually-hidden.scss#L8-L22
@mixin visually-hidden() {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    position: absolute;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes hideVisually {
    to {
        // Hides element while remaining focusable
        @include visually-hidden();
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(
        180deg,
        rgba(96, 99, 103, 0) 0%,
        rgba(96, 99, 103, 0.18) 36.46%,
        rgba(96, 99, 103, 0.96) 100%
    );
    pointer-events: none;
    padding: 4px;

    animation-duration: 300ms;
    animation-timing-function: ease, step-end;
    animation-fill-mode: forwards;

    &.visible {
        animation-name: fadeIn;
    }
    &:not(.visible) {
        animation-name: fadeOut, hideVisually;
    }
}

.stepSlider {
    margin: 0 20px 8px;
}

.navControls {
    display: flex;
    align-items: center;
    gap: 2px;
}

.stepCount {
    @include typography.action;
    color: colors.$white;
}

.prevStepButton,
.nextStepButton,
.enableZoomButton,
.copyLinkButton,
.fullscreenButton {
    color: colors.$white;
    pointer-events: auto;
    &:disabled {
        pointer-events: auto;
    }
}

.enableZoomButtonActive {
    background-color: colors.$grey600;
}

.nextStepButton {
    margin-right: auto;
}
