@use "../../typography";
@use "../../colors";

.title {
    @include typography.title2;
    color: colors.$fullblack;
    margin-bottom: 8px;
    padding: 0;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.description {
    @include typography.body1;
    color: colors.$fullblack;
    margin: 0 0 20px;
}

.textField {
    @include typography.body1;
    border-radius: 4px;
    min-height: 182px;
    padding: 8px 15px;

    &::placeholder {
        color: #999b9d;
    }
}

.buttonRow {
    margin-top: 24px;
    display: flex;
}

.button {
    @include typography.action;
    height: 40px;
    padding: 8px 16px;
    margin-left: 10px;

    &:first-child {
        margin-left: auto;
    }
}

.cancelButton {
    border-color: colors.$blue;
    color: colors.$blue;
}

.inviteButton {
    color: colors.$white;
}

.errorMessage {
    margin-left: 0;
    margin-right: 0;
    overflow-wrap: anywhere;
}
