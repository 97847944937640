@use "../../colors";
@use "../../typography";
@use "../../breakpoints";
@use "../../containers";

.button {
    min-width: 54px;
    border-color: colors.$grey200;
    color: colors.$grey200;
    font-weight: 400;

    padding: 3px 6px;
    font-size: 12px;
    line-height: 17px;

    @container #{containers.$slideshow} (min-width: #{breakpoints.$small}) {
        padding: 4px 8px;
        font-size: 13px;
        line-height: 18px;
    }

    @container #{containers.$slideshow} (min-width: #{breakpoints.$medium}) {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 20px;
    }

    &:hover {
        border-color: colors.$grey700;
        background-color: colors.$grey600;
    }
}
