@use "../../colors";

.container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.previewSlideshow {
    padding: 0;
    margin: 24px;
    box-sizing: border-box;
    width: 900px;
    height: 495px;
    border: 1px solid colors.$grey300;
    border-radius: 8px;
    overflow: hidden;
    // Slideshow needs its own background color for when it's fullscreen
    background-color: colors.$white;
}

.guideInfo {
    width: 900px;
}
