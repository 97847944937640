@use "../../typography";
@use "../../breakpoints";
@use "../../containers";
@use "../../colors";

.container {
    background-color: colors.$grey900;
    box-sizing: border-box;
    border-radius: 8px;
    min-width: 220px;
    max-width: 220px;
    padding: 17px;

    @container #{containers.$slideshow} (min-width: #{breakpoints.$small}) {
        max-width: 240px;
        padding: 18px;
    }
    @container #{containers.$slideshow} (min-width: #{breakpoints.$medium}) {
        max-width: 280px;
        padding: 20px;
    }
}

@mixin responsiveFont {
    font-size: 12px;
    line-height: 17px;
    @container #{containers.$slideshow} (min-width: #{breakpoints.$small}) {
        font-size: 13px;
        line-height: 18px;
    }
    @container #{containers.$slideshow} (min-width: #{breakpoints.$medium}) {
        font-size: 14px;
        line-height: 20px;
    }
}

.title {
    @include responsiveFont;
    font-weight: 500;
    color: colors.$white;
    overflow-wrap: anywhere;
}

.description {
    @include responsiveFont;
    font-weight: 400;
    color: colors.$white;
    overflow-wrap: anywhere;
    margin-top: 4px;
    @container #{containers.$slideshow} (min-width: #{breakpoints.$small}) {
        margin-top: 6px;
    }
    @container #{containers.$slideshow} (min-width: #{breakpoints.$medium}) {
        margin-top: 8px;
    }
}
