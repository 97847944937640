@use "../../../colors";
@use "../../../typography";

.pageContainer {
    height: 100%;
    width: 100%;
}

.contentContainer {
    display: flex;
    height: calc(100% - 62px);
    &.loading,
    &.error {
        background-color: colors.$purple10;
        align-items: center;
        justify-content: center;
    }
}

.editor {
    flex: 1;
    width: 100%;
}

.moreIcon {
    color: colors.$grey800;
    font-size: 20px;
}

.guideTopbarContainer {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
    .left {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 1;
        margin-right: 8px;
    }
    .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.pageTitleRoot {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 1.5px;
    &.readOnly {
        margin: 8px 8px 8px 15px;
    }
}

.pageTitle {
    @include typography.title3;
    color: colors.$fullblack;
}

.menu {
    background: colors.$white;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.04),
        0px 6px 20px rgba(0, 0, 0, 0.1);
}

.orgLogo {
    margin: 0 0 0 16px;
}

.logo {
    width: 38px;
    height: 38px;
}

.wordmark {
    width: 112px;
    height: 56px;
}

.logo img,
.wordmark img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.menuIcon {
    margin-right: 8px;
}
