@use "../../typography.scss";
@use "../../colors.scss";

.container {
    position: sticky;
    top: 16px;
    margin-right: 64px;
    padding: 22px 20px;
    width: 250px;
    border-radius: 8px;
    box-shadow:
        0px 6px 20px 0px rgba(0, 0, 0, 0.1),
        0px 2px 2px 0px rgba(0, 0, 0, 0.04);
}

.header {
    @include typography.body2;
    margin: 0;
}

.list {
    padding-top: 20px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: colors.$blue500;
}

.outlineItem {
    text-align: start;
    align-items: flex-start;
    @include typography.body1;
    display: flex;
    gap: 8px;
    color: colors.$blue500;
}
