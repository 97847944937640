@use "../../typography";
@use "../../colors";

.container {
    font-size: 14px;
    color: colors.$grey800;
}

.tabPanel:not([hidden]) {
    display: flex;
    flex-direction: column;
}

.snippetTextarea {
    @include typography.body1;
    border: 1px solid #eaecef;
    resize: none;
    outline: none;
    padding: 8px 10px;
    height: 50px;
    border-radius: 8px;

    &:disabled {
        opacity: 0.4;
    }
}

.linkInput {
    @include typography.body1;
    border: 1px solid #eaecef;
    outline: none;
    padding: 8px 10px;
    border-radius: 8px;

    &:disabled {
        opacity: 0.4;
    }
}

.copyButton {
    margin-top: 24px;
}

.privacySettings {
    margin-top: 24px;
}
