@use "../../colors.scss";

.detailbar {
    height: 30px;
    background: white;
    border-bottom: 1px solid colors.$grey300;
    display: flex;
    align-items: center;
    padding: 15px 16px;
}
