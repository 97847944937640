@use "../../colors.scss";
@use "../../typography.scss";

.pill {
    @include typography.caption2Regular;
    display: inline-block;
    background-image: url("/images/rocket-icon.svg");
    background-repeat: no-repeat;
    background-size: auto 12px;
    background-position: 8px center;
    background-color: colors.$purple500;
    border-radius: 16px;
    padding: 6px 12px 6px 28px;
    color: white;
}
