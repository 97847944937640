@use "../../colors";

.container {
    display: flex;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 0 70px;
    overflow-y: scroll;
    background-color: colors.$purple10;
    > button {
        margin-bottom: 15px;
    }
}

.stepEditor {
    align-self: stretch;
    height: 100%;
}

.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.addStepsButton {
    margin-top: 16px;
}

.hidden {
    display: none;
}
