@use "colors";

// https://rsms.me/inter/
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url("fonts/Inter-Regular.woff2?v=3.15") format("woff2"),
        url("fonts/Inter-Regular.woff?v=3.15") format("woff");
}

// https://rsms.me/inter/
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
        url("fonts/Inter-Medium.woff2?v=3.15") format("woff2"),
        url("fonts/Inter-Medium.woff?v=3.15") format("woff");
}

// https://rsms.me/inter/
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        url("fonts/Inter-SemiBold.woff2?v=3.15") format("woff2"),
        url("fonts/Inter-SemiBold.woff?v=3.15") format("woff");
}

// See https://deploy-preview-15--upbeat-shirley-608546.netlify.app/perfect-ish-font-fallback/
@font-face {
    font-family: "Inter-fallback";
    size-adjust: 107%;
    ascent-override: 90%;
    src: local("Arial");
}

body {
    margin: 0;
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    overscroll-behavior-y: none;
    &.no-cursor {
        cursor: none !important;
        * {
            pointer-events: none !important;
        }
    }
}

a {
    text-decoration: none;
}
