@use "../../colors";
@use "../../typography";

.tooltip {
    @include typography.caption2Regular;
    color: colors.$white;
    box-sizing: border-box;
    padding: 6px 11px;
    max-width: 236px;
    background: #393939;
    border-radius: 4px;
}
