@use "../../typography";

.container {
    display: flex;
    flex-direction: column;
}

.label {
    @include typography.body1;
    margin-bottom: 10px;
}

.root {
    @include typography.body1;
}

.input {
    box-sizing: border-box;
    height: 36px;
    padding: 8px 15px;
}
