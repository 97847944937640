@use "../../colors";
@use "../../typography";

.button {
    @include typography.action;
    color: colors.$purple500;
    box-sizing: border-box;
    border-color: colors.$purple500;
    border-radius: 4px;
    padding: 8px 20px;
    min-width: unset;
    // The active state increases border-width, and we decrease padding
    // to keep the button the same size. So we need to align the transition
    // of border-width and padding here.
    transition: all 0s;

    &:hover:not(&:active) {
        background-color: colors.$purple50;
    }

    &.disabled {
        background-color: colors.$purple10;
        border-color: colors.$purple200;
        color: colors.$purple200;
    }

    &:active {
        border-width: 2px;
        padding: 7px 19px;
        background-color: colors.$purple50;
    }
}
