@use "../../colors";
@use "../../typography";

.paper {
    padding: 22px 70px 32px;
    border-radius: 8px;
    background: colors.$purple10;
    box-shadow:
        0px 2px 4px 0px rgba(0, 0, 0, 0.12),
        0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.blurredBackground {
    position: absolute;
    left: 70px;
    right: 70px;
    top: 82px;
    bottom: 43px;
    background: conic-gradient(
        from 180deg at 50% 50%,
        rgba(80, 85, 241, 0.17) 0deg,
        rgba(219, 203, 249, 0.5) 93.75deg,
        rgba(246, 211, 217, 0.5) 183.75deg,
        rgba(80, 85, 241, 0.5) 277.5deg,
        rgba(241, 221, 226, 0.5) 360deg
    );
    filter: blur(98px);
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
}

.billingPeriodContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.billingPeriodButtonGroup {
    display: flex;
    gap: 6px;
    padding: 4px;
    background: colors.$grey200;
    border: 1px solid colors.$grey300;
    border-radius: 12px;

    // Need to increase specificity to override the toggle button group styles
    & .billingPeriodButton {
        margin: 0;
        border-radius: 8px;
        border: none;

        &:not(:first-of-type),
        &:not(:last-of-type) {
            margin: 0;
            border-radius: 8px;
            border: none;
        }
    }
}

.billingPeriodButton {
    @include typography.action;
    color: colors.$grey900;
    padding: 8px 15px;

    &.selected {
        background: colors.$white;
        color: colors.$grey900;
    }
}

.saving {
    @include typography.action;
    color: colors.$purple500;

    &.hidden {
        visibility: hidden;
    }
}

.pricingTiers {
    margin-top: 16px;
    display: flex;

    justify-content: center;
    gap: 16px;
}

.pricingTierCard {
    flex: 1;
    z-index: 1;
}
