@use "../../colors";

$markDefaultHeight: 10px;
$markHoveredHeight: 20px;
$minScreenshotThumbnailHeight: 80px;

.slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $markHoveredHeight;
}

.stepMark {
    position: relative;
    flex: 1;
    pointer-events: auto;
    padding: calc(#{$markHoveredHeight} - #{$markDefaultHeight}) 0;

    .bar {
        height: $markDefaultHeight;
        border-radius: 8px;
        background-color: colors.$grey50;
        &.filled {
            background-color: colors.$purple500;
        }
    }

    &:hover {
        cursor: pointer;
        .bar {
            transition: all 100ms ease-in-out;
            height: $markHoveredHeight;
        }
    }
}

.screenshotThumbnailContainer {
    filter: drop-shadow(0px 30px 70px rgba(0, 0, 0, 0.2));
    background: radial-gradient(
        90.16% 143.01% at 15.32% 21.04%,
        rgba(165, 239, 255, 0.2) 0%,
        rgba(110, 191, 244, 0.0447917) 77.08%,
        rgba(70, 144, 213, 0) 100%
    );
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(40px);
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    display: flex;
}

.screenshotThumbnailImage {
    height: max($minScreenshotThumbnailHeight, 24vh);
    border-radius: 8px;
}
