@use "../../colors";
@use "../../typography.scss";

.button {
    margin-right: 8px;
    border-radius: 4px;
    background-color: colors.$green600;
    &:hover,
    &:disabled,
    &:focus {
        background-color: colors.$green700;
    }
}

.text {
    @include typography.caption2Semibold;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
}
