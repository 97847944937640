@use "../../colors";
@use "../../typography";

.container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.deviceSelectionIcon {
    padding: 4px;
}

// Add MUI's classname to increase specificity
.select:global(.MuiSelect-select) {
    width: 250px;
    box-sizing: border-box;
    padding: 8px 33px 8px 17px;
    border: 1px solid colors.$grey300;
    @include typography.body1;
}

.selectMenuPaper {
    width: 250px;
    border-radius: 6px;
    background: colors.$white;
    box-shadow:
        0px 0px 0px 1px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.selectMenuList {
    padding: 0;
}

.menuItem {
    display: block;
    border-radius: 0;
    padding: 12px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include typography.leading5;

    &:hover {
        background-color: colors.$grey300;
    }

    &.menuItemSelected {
        background: inherit;
    }
}
