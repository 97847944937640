.dialogContainer {
    width: 460px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.closeButton {
    position: absolute;
    right: 25px;
    top: 25px;
}
