@use "../../colors";
@use "../../typography";

.paper {
    width: 100%;
    max-width: 322px;
    max-height: 50%;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 15px;
    font-weight: 500;
}

.body {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
}

.recordButton {
    @include typography.action;
    box-sizing: border-box;
    padding: 8px 16px;
    max-height: 36px;
    border-radius: 4px;
    color: colors.$white;
    margin-top: 8px;

    &:disabled {
        background-color: colors.$purple10;
        border-color: colors.$purple200;
        color: colors.$purple200;
    }
}

.recordIcon {
    font-size: 16px;
    margin-right: 6px;
}

.synthesizeButton {
    @include typography.action;
    box-sizing: border-box;
    padding: 8px 16px;
    max-height: 36px;
    border-radius: 4px;
    color: colors.$purple500;
    margin-top: 12px;

    &:disabled {
        background-color: colors.$purple10;
        border-color: colors.$purple200;
        color: colors.$purple200;
    }
}
