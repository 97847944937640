@use "../../colors";

$sidebar-width: 240px;

.container {
    height: 100vh;
    width: 100vw;
}

.sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;
    z-index: 1;
    border-right: 1px solid colors.$grey300;
}

.mainContentWithSidebar {
    padding-left: $sidebar-width;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: auto;
}
.mainContent {
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: auto;
}
