@use "../../colors";
@use "../../typography";

.container {
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.crownIcon {
    margin-right: 8px;
    height: 24px;
    width: 24px;
}

.title {
    @include typography.leading5;
    color: #101828;
}

.message {
    padding: 8px;
    border-radius: 3px;
    background: #f2f3f8;

    @include typography.caption2Regular;
    line-height: 20px;
    color: colors.$grey500;
}

.upgradeButton {
    @include typography.caption2Semibold;
    font-weight: 500;
    line-height: 20px;
    color: #5055f1;
    padding: 0 4px 0 0;
    justify-content: flex-start;
    border-radius: 0;
    width: fit-content;
}

.arrowIcon {
    margin-left: 8px;
    & > *:first-child {
        font-size: 16px;
    }
}
