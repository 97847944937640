@use "../../typography";
@use "../../colors";

.paper {
    padding: 20px;
    box-sizing: border-box;
    width: 320px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.heading {
    @include typography.action;
    margin: 0 0 20px;
}

.sectionHeading,
.label {
    @include typography.body2;
    color: colors.$fullblack;
    margin: 0 0 5px;
}

.titleTextField,
.descriptionTextField {
    margin: 5px 0 10px;
}

.titleTextFieldInput {
    @include typography.body1;
    height: 36px;
    box-sizing: border-box;
    padding: 8px 10px;
}

.descriptionTextFieldInput {
    @include typography.body1;
    box-sizing: border-box;
    padding: 8px 10px;
}

.tooltipInstructions {
    @include typography.caption2Regular;
    color: colors.$grey600;
    margin: 0 0 10px;
}

.tooltipOptions {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.tooltipPlacementSelect {
    @include typography.body1;
    color: colors.$grey800;
    flex: 1;
}

.tooltipPlacementSelectDisplay {
    padding: 8px 32px 8px 10px;
}

.tooltipOffsetTextField {
    flex: 1;
}

.tooltipOffsetInput {
    @include typography.body1;
    color: colors.$grey800;
    padding: 8px 10px;
}

.cancelButton {
    @include typography.action;
    color: colors.$purple500;
    min-height: 30px;
    min-width: 64px;
}

.saveButton {
    @include typography.action;
    color: colors.$white;
    min-height: 30px;
    min-width: 64px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 20px 0 0;
}
