@use "../../colors";
@use "../../typography";

.menuButton {
    border-radius: 4px;
    border-color: colors.$grey300;
    height: 36px;
    @include typography.body1;
}

.menuButtonIcon {
    color: colors.$grey400;
}

.popover {
    background: "#FFFFFF";
    box-shadow: "0px 2px 2px rgba(0, 0, 0, 0.04), 0px 6px 20px rgba(0, 0, 0, 0.1)";
}

.panelContainer {
    display: flex;
    flex-direction: row;
}

.panelLeft {
    width: 139px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    height: 311px;
}

.panelTitle {
    font-family: Inter, "Inter-fallback", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 21px 0 0 17px;
}

.toggleButtonGroup {
    margin: 8px;
}

.toggleButtonGroupVertical:not(:first-child),
.toggleButtonGroupVertical:not(:last-child) {
    border-radius: 4px;
}

.toggleButtonRoot {
    margin: 4px;
    border: none;
    justify-content: flex-start;
    @include typography.caption2Regular;
    color: colors.$grey800;
}

.toggleButtonRoot.toggleButtonSelected {
    background-color: colors.$purple10;
}

.toggleButtonRoot:hover,
.toggleButtonRoot.toggleButtonSelected:hover {
    background-color: colors.$purple50;
}
