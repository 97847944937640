@use "../../typography.scss";
@use "../../colors.scss";

.toggleButtonGroupRoot {
    border: 1px solid colors.$grey300;
    background-color: colors.$grey200;
    border-radius: 12px;
    padding: 4px;
    position: sticky;
    top: 16px;
    margin-right: auto;
    margin-left: auto;
    z-index: 100;
}

.toggleButtonRoot {
    border: none !important;
    border-radius: 8px !important;
    @include typography.textRegular;
    font-weight: 500;
}

.toggleButtonStandard {
    color: colors.$grey800;
    &:hover {
        background-color: colors.$grey300 !important;
    }
}

.toggleButtonSelected {
    background-color: white !important;
    color: black;
}

.active {
    color: black;
}

.label {
    @include typography.textMedium;
}

.inactive {
    color: colors.$grey800;
}

.icon {
    margin-right: 8px;
}
