@use "../../colors";
@use "../../typography";

$icon-size: 20px;

.iconButton {
    color: colors.$grey800;
    font-size: $icon-size;
}

.button {
    @include typography.leading5;
    color: colors.$white;
    font-weight: 500;
    padding: 9px 17px 9px 15px;
    min-width: 135px;
}

.icon {
    font-size: $icon-size;
    margin-right: 8px;
}
