@use "../../typography";
@use "../../colors";

.dialogPaper {
    border-radius: 10px;
}

.dialogContent {
    padding: 28px;
}

.dialogTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: colors.$fullblack;
    margin-bottom: 16px;
}

.dialogMessage {
    @include typography.textRegular;
    margin-bottom: 28px;
}

.deleteButton {
    background-color: colors.$red500;

    &:hover {
        background-color: colors.$red600;
    }
}

.cancelButton {
    margin-top: 12px;
}

.buttonRoot {
    @include typography.caption1Medium;
    height: 40px;
}

.buttonOutlinedPrimary {
    color: colors.$blue;
}

.buttonContained {
    color: colors.$white;
}
