$toolbarHeight: 48px;
$containerGap: 32px;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $containerGap;
    box-sizing: border-box;
    padding: 32px 0;
}

.editableStepScreenshot {
    align-self: stretch;
    flex: 1;
    max-height: calc(100% - #{$toolbarHeight} - #{$containerGap});
    margin: 0 auto;
}
