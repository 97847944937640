@use "../../colors";

.container {
    position: relative;
}

.overlay {
    position: absolute;
    inset: 0 0 0 0;
    background-color: rgba(colors.$grey400, 0.5);
    backdrop-filter: blur(4px);
}

.video {
    position: relative;
    height: 100%;
    width: 100%;
}

.playButton {
    position: absolute;
    margin: auto auto auto auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
