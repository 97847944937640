@use "../../../colors";
@use "../../../typography";

.pageContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: colors.$purple10;
}

.content {
    flex: 1;
    width: 100%;
    min-height: 0;
    background-color: #f8fafc;
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.slideshow {
    flex-grow: 1;
    min-height: 0;
    // Slideshow needs its own background color for when it's fullscreen
    background-color: colors.$grey50;
}
