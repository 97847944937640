@use "../../breakpoints";
@use "../../containers";

.container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 12px;
    @container #{containers.$slideshow} (min-width: #{breakpoints.$small}) {
        margin-top: 16px;
    }
    @container #{containers.$slideshow} (min-width: #{breakpoints.$medium}) {
        margin-top: 20px;
    }
}
