@use "../../colors";
@use "../../typography.scss";

.iconsRow {
    display: flex;
    gap: 30px;
    margin-bottom: 15px;
    align-items: center;
}

.iconContainer {
    height: 90px;
    width: 90px;
    border: solid 1px colors.$grey300;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chromeIcon,
.drivewayIcon {
    height: 50px;
    width: 50px;
}

.addIcon {
    height: 30px;
    width: 30px;
}

.title {
    @include typography.title2;
    margin-bottom: 8px;
    padding: 0 8px;
}

.description {
    @include typography.body1;
    margin: 8px 0 16px 0;
    padding: 0 8px;
}

.descriptionPara {
    margin: 0 0 16px 0;
}

.downloadButton {
    @include typography.action;
    color: colors.$white;
    width: 100%;
    padding: 12px 0;
}

.guideDetailPageLink {
    @include typography.caption2Regular;
    color: colors.$grey800;
    text-decoration: underline;
    margin-top: 36px;
}
