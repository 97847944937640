@use "../../../typography";
@use "../../../colors";
@use "../../../tint.scss";

$avatar-size: 30px;

.container {
    position: relative;
    display: flex;
    background: #f8fafc;
    flex-direction: column;
    height: 180px;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.08),
        0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    box-sizing: border-box;
}

.clickableBackgroundLink {
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
}

.actions {
    position: absolute;
    right: 8px;
    bottom: 8px;
    pointer-events: all;
}

.grey800 {
    color: colors.$grey800;
}

.white {
    color: colors.$white;
}

.menuItemIcon {
    color: colors.$grey800;
    font-size: 16px;
    margin-right: 7px;
}

.details {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    padding: 12px 12px 0;
}

.title {
    // TODO(arnav): Update to title3 (except line-height) when design system updated.
    font-family: Inter, "Inter-fallback", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: colors.$grey800;
    word-break: break-word;
    @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.starContainer {
    display: flex;
    width: 24px;
    height: 24px;
    margin-left: 4px;
}

.blankStar {
    opacity: 0.8;
}

.overlayStarContainer {
    position: absolute;
    right: 12px;
    top: 16px;
    width: 24px;
    height: 24px;
    pointer-events: all;
}

.tagsContainer {
    height: 30px;
    margin-bottom: 6px;
    padding: 0 12px;
    overflow: hidden;
}

.tagsIcon {
    @include tint.grey400;
    padding-bottom: 4px;
}

.realTags {
    display: flex;
    align-items: center;
}

.shadowTags {
    // add left margin to account for the tags icon
    margin-left: 24px;
    // add right margin to account for the overflow chip up to +99 tags
    margin-right: 44px;
    visibility: hidden;
}

.tagChip {
    margin-right: 4px;
}

.tagRow {
    display: flex;
    overflow: hidden;
}

.metadataContainer {
    font-size: 12px;
    color: colors.$grey600;
    background-color: colors.$white;
    padding: 8px 12px 8px;
    display: flex;
    flex-direction: row;
    border-radius: inherit;
}

.authorTextContainer {
    height: $avatar-size;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authorName {
    font-weight: 600;
}

.avatar {
    height: $avatar-size;
    width: $avatar-size;
    margin-right: 5px;
}

.viewCount {
    margin-left: auto;
    align-self: flex-end;
    @include typography.caption2Regular;
    color: colors.$grey2;
    display: flex;
}

.eyeIcon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    // Tints an icon #999b9d
    filter: brightness(0) saturate(100%) invert(74%) sepia(2%) saturate(304%) hue-rotate(169deg)
        brightness(84%) contrast(84%);
}

.container:hover .overlay,
.overlay.keepHoverState {
    opacity: 1;
}

.overlay {
    border-radius: inherit;
    opacity: 0;
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    pointer-events: none;
}

.menuPaper {
    background: colors.$white;
    box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.04),
        0px 6px 20px rgba(0, 0, 0, 0.1);
}
