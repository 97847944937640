@use "../../colors";

$buttonSize: 160px;
$scaleOnHover: 1.125;
$transitionTime: 0.4s;

.button {
    width: $buttonSize;
    height: $buttonSize;
    padding: 0;
    border-radius: calc(#{$buttonSize} / 2);
    border: none;
    background-color: rgba(#d9dadb, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all $transitionTime ease-out;

    &:hover,
    &:focus {
        cursor: pointer;
        scale: $scaleOnHover;
    }
}

.inner {
    width: calc(#{$buttonSize} - 20px);
    height: calc(#{$buttonSize} - 20px);
    border-radius: calc((#{$buttonSize} - 20px) / 2);
    background-color: colors.$white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.playIcon {
    height: 60px;
    position: relative;
    left: 6px;
}
