@mixin shadow2 {
    box-shadow:
        0px 0px 20px rgba(0, 0, 0, 0.04),
        0px 0px 3.25px rgba(0, 0, 0, 0.03);
}

@mixin shadow4 {
    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.15),
        0px 4px 8px rgba(236, 236, 236, 0.9);
}

@mixin shadow16 {
    box-shadow:
        0px 0px 0px 1px rgba(0, 0, 0, 0.03),
        0px 38px 80px rgba(0, 0, 0, 0.02),
        0px 14.637px 25.4815px rgba(0, 0, 0, 0.0242963),
        0px 3.0963px 6.51852px rgba(0, 0, 0, 0.0157037);
}
